#page-topbar {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	z-index: 1002;
	background-color: #ffffff;
	-webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
	box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}

.navbar-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	height: 50px;
	padding: 0 20px;
}

.navbar-header .dropdown .show.header-item {
	background-color: #f8f9fa;
}

body.vertical-collpsed #page-topbar {
	left: 70px;
}

@media (max-width: 991.98px) {
	body.vertical-collpsed #page-topbar {
		left: 0;
	}
}

body:not(.vertical-collpsed) .navbar-header .vertical-menu-btn {
	display: none;
}

body:not(.vertical-collpsed) .app-search {
	margin-left: 10px;
}

.navbar-brand-box {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-dropdown {
	position: relative;
}

.custom-dropdown-title {
	position: relative;
	font-size: 20px;
	font-weight: 600;
	cursor: pointer;
}

.custom-dropdown-title .uil-angle-down {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.custom-dropdown-items-back {
	padding-top: 30px;
	position: absolute;
	top: 20px;
}

.custom-dropdown.active .custom-dropdown-items {
	display: block;
	opacity: 1;
	transition: 0.3s all;
	animation: menu-sub-dropdown-animation-fade-in .3s ease 1, menu-sub-dropdown-animation-move-up .3s ease 1;
	transform: translateY(0);
	transition: transform .2s ease
}

.custom-dropdown-items {
	display: none;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
	z-index: 10;
	/* padding: 12px 24px; */
	border-radius: 8px;
	transform: translateY(50px);
	width: 200px;
	height: auto;
}

@keyframes menu-sub-dropdown-animation-fade-in {
	from {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@keyframes menu-sub-dropdown-animation-move-up {
	from {
		margin-top: .75rem
	}

	to {
		margin-top: 0
	}
}

@keyframes menu-sub-dropdown-animation-move-down {
	from {
		margin-bottom: .75rem
	}

	to {
		margin-bottom: 0
	}
}

.custom-dropdown-item {
	font-size: 20px;
	font-weight: 500;
	cursor: pointer;
	padding: 10px 24px;
	color: #000;
}

.custom-dropdown-item:hover {
	transition: 0.3s;
	background-color: #f5f6f8;
}

.logo {
	height: 100%;
	display: flex;
	align-items: center;
}

.logo .logo-lg {
	display: block;
}

.logo .logo-sm {
	display: none;
}

.logo-dark {
	display: flex;
}

.logo-light {
	display: none;
}

/* Search */
.app-search {
	padding: calc(32px / 2) 0;
}

.app-search .form-control {
	border: none;
	height: 38px;
	padding-left: 40px;
	padding-right: 20px;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 5px;
}

.app-search span {
	position: absolute;
	z-index: 10;
	font-size: 16px;
	line-height: 38px;
	left: 13px;
	top: 0;
	color: #74788d;
}

.megamenu-list li {
	position: relative;
	padding: 5px 0px;
}

.megamenu-list li a {
	color: #495057;
}

@media (max-width: 991.98px) {
	.navbar-brand-box {
		width: auto;
	}

	#page-topbar {
		left: 0;
	}

	#page-topbar .navbar-header .navbar-brand-box {
		display: inline-block;
		position: relative;
	}

	#page-topbar .navbar-header .vertical-menu-btn {
		display: inline-block;
	}

	.logo span.logo-lg {
		display: none;
	}

	.logo span.logo-sm {
		display: inline-block;
	}
}

.page-content {
	padding: 60px 20px 10px 20px;
}

.header-item {
	height: 50px;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: #555b6d;
	border: 0;
	border-radius: 0px;
}

.header-item:hover {
	color: #555b6d;
}

.header-profile-user {
	height: 36px;
	width: 36px;
	background-color: #f6f6f6;
	padding: 3px;
}

.noti-icon i {
	font-size: 22px;
	color: #555b6d;
}

.noti-icon .badge {
	position: absolute;
	top: 12px;
	right: 5px;
}

.notification-item .d-flex {
	padding: 0.75rem 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.notification-item .d-flex:hover {
	background-color: #f8f9fa;
}

.dropdown-icon-item {
	display: block;
	border-radius: 3px;
	line-height: 34px;
	text-align: center;
	padding: 15px 0 9px;
	display: block;
	border: 1px solid transparent;
	color: #74788d;
}

.dropdown-icon-item img {
	height: 24px;
}

.dropdown-icon-item span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dropdown-icon-item:hover {
	border-color: #f5f6f8;
}

.fullscreen-enable [data-bs-toggle="fullscreen"] .uil-minus-path::before {
	content: '\eb8d';
}

body[data-topbar="dark"] #page-topbar {
	background-color: #2f374e;
}

body[data-topbar="dark"] .navbar-header .dropdown .show.header-item {
	background-color: rgba(255, 255, 255, 0.05);
}

body[data-topbar="dark"] .navbar-header .waves-effect .waves-ripple {
	background: rgba(255, 255, 255, 0.4);
}

body[data-topbar="dark"] .header-item {
	color: #e9ecef;
}

body[data-topbar="dark"] .header-item:hover {
	color: #e9ecef;
}

body[data-topbar="dark"] .header-profile-user {
	background-color: rgba(255, 255, 255, 0.25);
}

body[data-topbar="dark"] .noti-icon i {
	color: #e9ecef;
}

body[data-topbar="dark"] .logo-dark {
	display: none;
}

body[data-topbar="dark"] .logo-light {
	display: block;
}

body[data-topbar="dark"] .app-search .form-control {
	background-color: #37405b;
	color: #fff;
}

body[data-topbar="dark"] .app-search span,
body[data-topbar="dark"] .app-search input.form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar="dark"] .navbar-brand-box {
	background: #1c2742;
}

body[data-sidebar="dark"] .navbar-brand-box .logo-dark {
	display: none;
}

body[data-sidebar="dark"] .navbar-brand-box .logo-light {
	display: block;
}

.nav {
	height: 100%;
	display: flex;
	align-items: center;
}

.nav-dropdown a {
	padding: 0 15px !important;
	height: 100%;
	font-size: 24px;
}

.nav-dropdown .dropdown-item {
	font-size: 18px;
}

@media (max-width: 600px) {
	.navbar-header .dropdown {
		position: static;
	}

	.navbar-header .dropdown .dropdown-menu {
		left: 10px !important;
		right: 10px !important;
	}
}

@media (max-width: 380px) {
	.navbar-brand-box {
		display: none;
	}
}

body[data-layout="horizontal"] .page-content {
	margin-top: 70px;
	padding: calc(80px + 1.25rem) calc(1.25rem / 2) 60px calc(1.25rem / 2);
}

@media (max-width: 991.98px) {
	body[data-layout="horizontal"] .page-content {
		margin-top: 0px;
	}
}

@media (max-width: 374.99px) {
	.navbar-brand-box {
		padding: 0 1rem;
		text-align: center;
	}

	.language-switch {
		display: none !important;
	}
}